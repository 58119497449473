import React, { useEffect, useCallback, useMemo } from "react";
import { Button, Grid, Typography } from "@mui/material";
import AddLocationIcon from "@mui/icons-material/AddLocation";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { session } from "../session/constants";

import { useDispatch, useSelector } from "react-redux";
import {
  setCiudades,
  setPais,
  setZona,
} from "../redux/actions/productsActions";
import Publish from "./Propertier/Publish/Publish";

const sessionGet = sessionStorage.getItem(session.SESSION_VALUE);

const Categorias = () => {
  const [venta, setVenta] = React.useState("");
  const [checked, setChecked] = React.useState([true, false]);
  const [zonaState, setZonaState] = React.useState("");
  const [paisState, setPaisState] = React.useState("");

  let city = useSelector((state) => state.allProducts.ciudades);
  let zone = useSelector((state) => state.allProducts.zona);
  let pais = useSelector((state) => state.allProducts.pais);
  const dispatch = useDispatch();

  let history = useHistory();
  const handleClick = () => {
    history.push("/publish");
  };
  const fetchRegion = async () => {
    const response = await axios
      .get("https://back.rentti.cl/v1/property/city")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setCiudades(response.data));
  };

  const fetchZone = async () => {
    const response = await axios
      .get("https://back.rentti.cl/v1/property/zone")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setZona(response.data));
  };

  const fetchPais = async () => {
    const response = await axios
      .get("https://back.rentti.cl/v1/property/country")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setPais(response.data));
  };

  const cityValues = {
    options: city.map((option) => option.nombre),
  };

  useEffect(() => {
    fetchRegion();
    fetchZone();
    fetchPais();
  }, []);

  const handleChange = (event) => {
    setZonaState(event.target.value);
  };

  const handleChangeC = (event) => {
    setChecked([event.target.checked, event.target.checked]);
  };

  const handleChangeP = (event) => {
    setPaisState(event.target.value);
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <Typography
          variant="h5"
          textAlign="center"
          display="block"
          gutterBottom
        >
          Departamentos en Venta
        </Typography>
        <Typography
          variant="caption"
          marginLeft="60px !important"
          display="block"
          gutterBottom
        >
          373 resultados
        </Typography>
      </Grid>
      <Grid item xs={9}>
        {/* <Button
          variant="contained"
          color="primary"
          // className={classes.button}
          startIcon={<AddLocationIcon />}
        >
          Ver mapa
        </Button> */}

        <Grid container spacing={1} alignItems="center">
          <Grid item xs={3}>
            <Box
              sx={{
                with: "100%",
                background: "#fff",
                borderColor: "#fff",
                marginRight: "5px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Pais
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder="Pais"
                  label="Pais"
                  onChange={handleChangeP}
                  size="small"
                >
                  {pais.map((item) => (
                    <MenuItem
                      sx={{
                        display: "block !important",
                        padding: "5px 10px !important",
                      }}
                      key={item.id}
                      value={item.nombre}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                with: "100%",
                background: "#fff",
                borderColor: "#fff",
                marginRight: "5px",
              }}
            >
              <FormControl fullWidth>
                <InputLabel size="small" id="demo-simple-select-label">
                  Zona
                </InputLabel>

                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Zona"
                  size="small"
                  onChange={handleChange}
                >
                  {zone.map((item) => (
                    <MenuItem
                      sx={{
                        display: "block !important",
                        padding: "5px 10px !important",
                      }}
                      key={item.id}
                      value={item.nombre}
                    >
                      {item.nombre}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              size="small"
              {...cityValues}
              id="flat-demo"
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  label="Ciudad"
                  variant="standard"
                />
              )}
            />
          </Grid>
          {sessionGet == session.SESSION_ON ? (
            <Grid item xs={3} textAlign="center">
              <Button
                mt={3}
                variant="outlined"
                color="primary"
                onClick={() => handleClick()}
                //  className={classes.button}
              >
                Publica tu propiedad
              </Button>
            </Grid>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Categorias;
