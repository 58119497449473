import React, { useEffect, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea, Grid } from "@mui/material";
import image from "./img.png";
import BedIcon from "@mui/icons-material/Bed";
import ShowerIcon from "@mui/icons-material/Shower";
import ApartmentIcon from "@mui/icons-material/Apartment";
import ApiIcon from "@mui/icons-material/Api";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setProducts } from "../redux/actions/productsActions";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";
import IconButton from "@mui/material/IconButton";

const ProductComponent = (props) => {
  console.log("props", props);
  const { resultadoFilter } = props;
  const products = useSelector((state) => state.allProducts.products);
  const dispatch = useDispatch();
  const fetchProducts = async () => {
    const response = await axios
      .get("https://back.rentti.cl/v1/property")
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(setProducts(response.data));
  };
  useEffect(() => {
    fetchProducts();
  }, []);

  const renderList = products.map((product) => {
    const {
      id,
      titulo,
      descripcion,
      valor,
      dormitorios,
      banios,
      tipo_propiedad_nu,
      construido_m2,
      direccion,
    } = product;
    return (
      <Grid container justifyContent="left" alignContent="center">
        <Link to={`/product/${id}`}>
          <Grid item xs={12} mb={1}>
            <Card key={product.id}>
              <CardActionArea sx={{ width: "900px" }}>
                <Grid container>
                  <Grid item xs={4}>
                    <CardMedia
                      component="img"
                      image={image}
                      key={image}
                      // alt={title}
                      className="image"
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <CardContent>
                      <Grid container>
                        <Grid item xs={11}>
                          <Typography gutterBottom variant="h5" component="div">
                            {titulo}
                          </Typography>
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton variant="plain">
                            <FavoriteBorder />
                          </IconButton>
                        </Grid>
                      </Grid>
                      <Grid container mt={3}>
                        <Grid item xs={3} display="flex">
                          <AttachMoneyIcon />
                          {
                            <Typography
                              gutterBottom
                              variant="subtitle2"
                              component="div"
                            >
                              {valor}
                            </Typography>
                          }
                        </Grid>
                      </Grid>
                      <Typography variant="subtitle2" color="text.secondary">
                        {descripcion}
                      </Typography>
                      <Grid container mt={3}>
                        <Grid item xs={3} display="flex">
                          <BedIcon />
                          {
                            <Typography
                              gutterBottom
                              variant="subtitle2"
                              component="div"
                            >
                              {dormitorios}
                            </Typography>
                          }
                        </Grid>
                        <Grid item xs={3} display="flex">
                          <ShowerIcon />
                          {
                            <Typography
                              gutterBottom
                              variant="subtitle2"
                              component="div"
                            >
                              {banios}
                            </Typography>
                          }
                        </Grid>
                        <Grid item xs={3} display="flex">
                          <ApartmentIcon />
                          {
                            <Typography
                              gutterBottom
                              variant="subtitle2"
                              component="div"
                            >
                              {construido_m2 + " m²"}
                            </Typography>
                          }
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Grid>
                </Grid>
              </CardActionArea>
            </Card>
          </Grid>
        </Link>
      </Grid>
    );
  });
  return <>{renderList}</>;
};

export default ProductComponent;
