import React, { useEffect, useCallback, useMemo, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Checkbox, Grid, TextField, Typography } from "@mui/material";
import Button from "@material-ui/core/Button";
import axios from "axios";
import FormControlLabel from "@mui/material/FormControlLabel";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { session } from "../../session/constants";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 800,
  },
}));

export default function QuoteNow(props) {
  const { open, handleClose, propsProduct } = props;
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [cotización, setCotizacion] = React.useState({
    nombre: "",
    apellidos: "",
    email: "",
    telefono: "",
    rut: "",
    mensaje: "",
    TyC: "",
    idpropiedad: propsProduct.id,
  });
  console.log(propsProduct.id);
  const [token, setToken] = useState(
    sessionStorage.getItem(session.SESSION_TOKEN)
  );
  const handleCloseSuccess = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };
  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };
  const handleSubmitPost = async (e) => {
    const dataFile = new FormData();
    dataFile.append("nombre", cotización.nombre);
    dataFile.append("apellidos", cotización.apellidos);
    dataFile.append("email", cotización.email);
    dataFile.append("telefono", cotización.telefono);
    dataFile.append("rut", cotización.rut);
    dataFile.append("mensaje", cotización.mensaje);
    dataFile.append("TyC", cotización.TyC ? 1 : 0);
    dataFile.append("idpropiedad", propsProduct.id);

    try {
      const response = await axios({
        method: "post",
        url: `https://back.rentti.cl/v1/form`,
        headers: {
          Authorization: `Bearer ${token}`,
        },
        data: dataFile,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleSubmit = async (e) => {
    // store the states in the form data
    e.preventDefault();
    try {
      // make axios post request
      // const response = await axios
      //   .get(`https://api.libreapi.cl/rut/validate?rut=${cotización.rut}`)
      //   .catch((err) => {
      //     console.log("Err: ", err);
      //   });
      // if (response.status == "200") {
      //   handleSubmitPost();
        setOpenSuccess(true);
      // }
      handleClose();
    } catch (error) {
      setOpenError(true);
      console.log(error);
    }
  };

  function handleCotizacion(e) {
    setCotizacion((userValues) => ({
      ...userValues,
      [e.target.name]: e.target.value,
    }));
  }

  function handleCheck(e) {
    setCotizacion((userValues) => ({
      ...userValues,
      [e.target.name]: e.target.checked,
    }));
  }
  const classes = useStyles();

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form onSubmit={handleSubmit}>
              <h2 id="transition-modal-title">
                Ingrese su información para cotización del modelo
              </h2>
              <Grid container mt={"10px !important"} spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h6">{propsProduct.titulo}</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  ml={"20px !important"}
                  mb={"10px !important"}
                >
                  <Typography variant="body1">Incluye: </Typography>
                  <Typography variant="body1">
                    <i className="bed icon" />
                    <b>{propsProduct.dormitorios} dormitorios</b>
                  </Typography>
                  <Typography variant="body1">
                    <i className="bath icon" />
                    <b>{propsProduct.banios}</b> baños
                  </Typography>
                  <Typography variant="body1">
                    <i className="external alternate icon" />
                    <b>{propsProduct.terreno_m2}</b> m2
                  </Typography>
                  <Typography variant="body1">
                    <i className="bath icon" />
                    <b>{propsProduct.banios}</b> baños
                  </Typography>
                  <Typography variant="body1">
                    Ubicado en <b>{propsProduct.direccion}</b> en la ciudad de{" "}
                    <i className="map marker alternate icon" />
                    <b>{propsProduct.ciudad}</b>
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-basic"
                    name="nombre"
                    label="Nombres"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleCotizacion}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-basic"
                    name="apellidos"
                    label="Apellidos"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleCotizacion}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-basic"
                    name="telefono"
                    label="Teléfono"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleCotizacion}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-basic"
                    name="rut"
                    label="Rut"
                    variant="outlined"
                    size="small"
                    fullWidth
                    value={cotización.rut}
                    onChange={handleCotizacion}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-basic"
                    name="email"
                    label="Email"
                    variant="outlined"
                    size="small"
                    fullWidth
                    onChange={handleCotizacion}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="outlined-basic"
                    name="mensaje"
                    label="Mensaje"
                    variant="outlined"
                    size="small"
                    rows={3}
                    multiline
                    fullWidth
                    onChange={handleCotizacion}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  mt={"10px !important"}
                  ml={"10px !important"}
                >
                  <FormControlLabel
                    name="TyC"
                    control={<Checkbox />}
                    label="Acepto los Términos y condiciones y autorizo el uso de mis datos de acuerdo a la Declaración de Privacidad."
                    onChange={handleCheck}
                  />
                </Grid>

                <Grid mt={4} mb={2} xs={12} textAlign="center">
                  <Button
                    mt={3}
                    variant="contained"
                    color="primary"
                    type="submit"
                    //  className={classes.button}
                  >
                    Cotizar Unidad
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>

      <Snackbar
        open={openSuccess}
        autoHideDuration={3000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%" }}
        >
          ¡Cotizacion enviado satisfactoriamente!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openError}
        autoHideDuration={3000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          ¡El rut ingresado no es válido!
        </Alert>
      </Snackbar>
    </div>
  );
}
