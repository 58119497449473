import React, { useEffect, useCallback, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { Grid, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import axios from "axios";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    borderRadius: "15px",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: 350,
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function User(props) {
  const { login } = props;
  // const[confirmPass, setConfirmPass]=React.useState("");
  const [usuario, setUsuario] = React.useState({
    nombre: "",
    apellido: "",
    telefono: "999999999",
    rut: "",
    estado: 1,
    user: "",
    email: "",
    password: "",
    passwordSame: "",
    tipo: 2,
    vendedor: 1,
    captador: 1,
    visible_web: 1,
  });
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [openA, setOpenA] = React.useState(false);
  const [openE, setOpenE] = React.useState(false);

  const handleClickA = () => {
    setOpenA(true);
  };

  const handleCloseA = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenA(false);
  };
  const handleClickE = () => {
    setOpenE(true);
  };

  const handleCloseE = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenE(false);
  };

  function handleUsuario(e) {
    setUsuario((userValues) => ({
      ...userValues,
      [e.target.name]: e.target.value,
    }));
  }

  const [firtsPage, setFirtsPage] = React.useState(true);

  const handleSubmit = async (e) => {
    // store the states in the form data
    e.preventDefault();
    try {
      if (usuario.password == usuario.passwordSame) {
        const response = await axios({
          method: "post",
          url: "https://back.rentti.cl/v1/user",
          data: {
            nombre: usuario.nombre,
            apellido: usuario.apellido,
            telefono: usuario.telefono,
            rut: usuario.rut,
            estado: usuario.estado,
            user: usuario.user,
            email: usuario.email,
            password: usuario.password,
            tipo: usuario.tipo,
            vendedor: usuario.vendedor,
            captador: usuario.captador,
            visible_web: usuario.visible_web,
          },
        });
        console.log(response);
        handleClose();
        handleClickA();
        limpiaDatos();
        handleFirtPage();
        login();
      } else {
        alert("Las contraseñas deben coincidir");
      }
      // make axios post request
    } catch (error) {
      handleClickE();
    }
  };
  const limpiaDatos = () => {
    setUsuario((userValues) => ({
      ...userValues,
      rut: "",
      nombre: "",
      apellido: "",
      telefono: "",
      user: "",
      email: "",
      password: "",
      passwordSame: "",
    }));
  };
  const handleNextPage = () => {
    setFirtsPage(false);
  };
  const handleFirtPage = () => {
    setFirtsPage(true);
  };

  const { open, handleClose } = props;
  const classes = useStyles();

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <form onSubmit={handleSubmit}>
              <Grid container>
                {firtsPage ? (
                  <Grid item container spacing={1}>
                    <Grid item xs={12}>
                      <h2 id="transition-modal-title">Completa tus datos</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="rut"
                        id="outlined-basic"
                        label="Rut"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={usuario.rut}
                        onChange={handleUsuario}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="nombre"
                        id="outlined-basic"
                        label="Nombres"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={usuario.nombre}
                        onChange={handleUsuario}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="apellido"
                        id="outlined-basic"
                        label="Apellidos"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={usuario.apellido}
                        onChange={handleUsuario}
                      />
                    </Grid>
                    <Grid container mt={1} xs={12} justifyContent="flex-end">
                      <Button
                        style={{ color: "#000", marginRight: "10px" }}
                        onClick={handleClose}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNextPage}
                      >
                        Siguiente
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid item container spacing={1} mt={"-25px !important"}>
                    <Grid item xs={12} display="flex" alignItems={"baseline"}>
                      <IconButton onClick={handleFirtPage} size="small">
                        <ArrowBackIosIcon fontSize="inherit" />
                      </IconButton>
                      <h2>Cree un usuario</h2>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="user"
                        id="outlined-basic"
                        label="Usuario"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={usuario.user}
                        onChange={handleUsuario}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="email"
                        id="outlined-basic"
                        label="Email"
                        variant="outlined"
                        size="small"
                        fullWidth
                        value={usuario.email}
                        onChange={handleUsuario}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={values.showPassword ? "text" : "password"}
                          value={usuario.password}
                          size="small"
                          fullWidth
                          name="password"
                          placeholder="Contraseña"
                          onChange={handleUsuario}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl variant="outlined" fullWidth>
                        <OutlinedInput
                          id="outlined-adornment-password"
                          type={values.showPassword ? "text" : "password"}
                          value={usuario.passwordSame}
                          size="small"
                          name="passwordSame"
                          fullWidth
                          placeholder="Contraseña"
                          onChange={handleUsuario}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {values.showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}></Grid>
                    <Grid mt={1} xs={12} textAlign="right">
                      <Button
                        style={{ color: "#000", marginRight: "10px" }}
                        onClick={handleClose}
                      >
                        Cancelar
                      </Button>
                      <Button variant="contained" color="primary" type="submit">
                        Enviar datos
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </form>
          </div>
        </Fade>
      </Modal>
      <Snackbar open={openA} autoHideDuration={6000} onClose={handleCloseA}>
        <Alert onClose={handleCloseA} severity="success" sx={{ width: "100%" }}>
          ¡Felicidades, usuario creado! \nRedirigiendo a inicio de sesión ...
        </Alert>
      </Snackbar>
      <Snackbar open={openE} autoHideDuration={6000} onClose={handleCloseE}>
        <Alert onClose={handleCloseE} severity="warning" sx={{ width: "100%" }}>
          Las contraseñas deben coincidir
        </Alert>
      </Snackbar>
    </div>
  );
}
