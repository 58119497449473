import React, { useState, useEffect } from "react";
import {
  Typography,
  TextField,
  Button,
  Stepper,
  Step,
  StepLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import axios from "axios";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step0 from "./Step0";
import Step4 from "./Step4";
import { session } from "../../../session/constants";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { selectedProduct } from "../../../redux/actions/productsActions";


const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
}));

function getSteps() {
  return ["Paso 1", "Paso 2", "Paso 3", "Paso 4", "Paso 5"];
}

const Publish = () => {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [skippedSteps, setSkippedSteps] = useState([]);
  const steps = getSteps();
  const [dataEntity, setDataEntity] = useState([]);
  const [id_usuario, setIdUsuario] = useState(0);
  const [token, setToken] = useState(
    sessionStorage.getItem(session.SESSION_TOKEN)
  );
  const { idEdit } = useParams();
  const [dataEdit, setDataEdit] = useState({});

  const [stepFirts, setStepFirts] = useState({
    id_corredor: "",
    id_tipo_propiedad: "",
    id_operacion: "",
    anio_construccion: "",
    fecha_publicacion: "",
  });
  const [stepSecond, setStepSecond] = useState({
    titulo: "",
    direccion: "",
    id_tipo_nu: "", //tipo de popiedad(nueva, susada, entrega futura)
    id_pais: "",
    id_ciudad: "",
    id_comuna: "",
    id_zona: "",
    id_region: "",
    descripcion: "",
  });
  const [stepThird, setStepThird] = useState({
    longitud: "",
    latitud: "",
    superficie_terreno: "", //Superficie Total
    mt_utiles: "", //Superficie Util
    sup_hab: "", //Superficie Habitacións
    mts_terrazas: "", //Terraza
    mts_totales: "", //Terreno Total
    mts2_totales: "", //Terreno Total
    mts_construidos: "", //Terreno construido
    construido_m2: "", //Terreno construido
    longitud_frente: "", // Longitud frente
    longitud_fondo: "", //Longitud Fondo
  });
  const [stepFourth, setStepFourth] = useState({
    dormitorios: "",
    banios: "",
    n_bodega: "",
    n_estacionamiento: "",
    pisos_edificio: "",
    tipo_calefaccion: "",
    tipo_piso: "",
    tipo_techumbre: "",
  });
  const [stepFifth, setStepFifth] = useState({
    id_moneda: "",
    valor: "",
    // falta pra caracteristicas extras:""
    gastos_comunes: 0,
    sala_reuniones: 0,
    vista_depejada: 0,
    garantia: 0,
    privados: 0,
    contribuciones: 0,
    colocacion: 0,
    orientacion: 0,
    custodia: 0,
    letras: 0,
    secretaria: 0,
    administracion: 0,
    estacionamineto_visitas: 0,
    img_referencial: null
  });
  const { id_corredor, id_tipo_propiedad, id_operacion, anio_construccion, fecha_publicacion } = stepFirts;

  const { titulo, direccion, id_tipo_nu, id_pais, id_ciudad, id_comuna, id_zona, id_region, descripcion } = stepSecond;

  const { longitud, latitud, superficie_terreno, mt_utiles, sup_hab, mts_terrazas, mts_totales, mts2_totales, mts_construidos, construido_m2, longitud_frente, longitud_fondo } = stepThird;

  const { dormitorios, banios, n_bodega, n_estacionamiento, pisos_edificio, tipo_calefaccion, tipo_piso, tipo_techumbre } = stepFourth;

  const { id_moneda, valor, gastos_comunes, sala_reuniones, vista_depejada, garantia, privados, contribuciones, colocacion, orientacion, custodia, letras, secretaria, administracion, estacionamineto_visitas, img_referencial } = stepFifth;

  //MÉTODO PARA EDITAR


  let product = useSelector((state) => state.product);

  const dispatch = useDispatch();
  const fetchProductDetail = async () => {
    const response = await axios
      .get(`https://back.rentti.cl/v1/property/oneProperty/${idEdit}`)
      .catch((err) => {
        console.log("Err: ", err);
      });
    dispatch(selectedProduct(response.data));

    setStepFirts(prev => ({
      ...prev,
      id_tipo_propiedad: response.data.detail.[0].idtipopropiedad,
      id_corredor: response.data.detail.[0].idcorredor,
      id_operacion: response.data.detail.[0].idtipooperacion,
      anio_construccion: response.data.detail.[0].anio_construccion,
      fecha_publicacion: response.data.detail.[0].fecha_publicacion
    }));
    setStepSecond(prev => ({
      ...prev,
      descripcion: response.data.detail.[0].descripcion,
      titulo: response.data.detail.[0].titulo,
      direccion: response.data.detail.[0].direccion,
      id_tipo_nu: response.data.detail.[0].idtipopropiedadnu,
      id_pais: response.data.detail.[0].idpais,
      id_ciudad: response.data.detail.[0].idciudad,
      id_comuna: response.data.detail.[0].iddistrito,
      id_zona: response.data.detail.[0].idzona,
      id_region: response.data.detail.[0].idregion
    }));
    setStepThird(prev => ({
      ...prev,
      longitud: response.data.detail.[0].longitud,
      latitud: response.data.detail.[0].latitud,
      superficie_terreno: response.data.detail.[0].superficie_terreno,
      mt_utiles: response.data.detail.[0].mt_utiles,
      sup_hab: response.data.detail.[0].sup_hab,
      mts_terrazas: response.data.detail.[0].mts_terrazas,
      mts_totales: response.data.detail.[0].mts_totales,
      mts2_totales: response.data.detail.[0].mts2_totales,
      mts_construidos: response.data.detail.[0].mts_construidos,
      construido_m2: response.data.detail.[0].construido_m2,
      longitud_frente: response.data.detail.[0].longitud_frente,
      longitud_fondo: response.data.detail.[0].longitud_fondo
    }));
    setStepFourth(prev => ({
      ...prev,
      dormitorios: response.data.detail.[0].dormitorios,
      banios: response.data.detail.[0].banios,
      n_bodega: response.data.detail.[0].n_bodega,
      n_estacionamiento: response.data.detail.[0].n_estacionamiento,
      pisos_edificio: response.data.detail.[0].pisos_edificio,
      tipo_calefaccion: response.data.detail.[0].tipo_calefaccion,
      tipo_piso: response.data.detail.[0].tipo_piso,
      tipo_techumbre: response.data.detail.[0].tipo_techumbre
    }));
    setStepFifth(prev => ({
      ...prev,
      id_moneda: response.data.detail.[0].idtipomoneda,
      valor: response.data.detail.[0].valor,
      gastos_comunes: response.data.detail.[0].gastos_comunes,
      sala_reuniones: response.data.detail.[0].sala_reuniones,
      vista_depejada: response.data.detail.[0].vista_depejada,
      garantia: response.data.detail.[0].garantia,
      privados: response.data.detail.[0].privados,
      contribuciones: response.data.detail.[0].contribuciones,
      colocacion: response.data.detail.[0].colocacion,
      orientacion: response.data.detail.[0].orientacion,
      custodia: response.data.detail.[0].custodia,
      letras: response.data.detail.[0].letras,
      secretaria: response.data.detail.[0].secretaria,
      administracion: response.data.detail.[0].administracion,
      estacionamineto_visitas: response.data.detail.[0].estacionamineto_visitas,
      img_referencial: response.data.detail.[0].img_referencial
    }));

  };
  useEffect(() => {
    idEdit && fetchProductDetail();
  }, []);

  console.log("Data del producto", product);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <Step0 stepFirts={stepFirts} setStepFirts={setStepFirts} />;
      case 1:
        return <Step1 stepSecond={stepSecond} setStepSecond={setStepSecond} />;
      case 2:
        return <Step2 stepThird={stepThird} setStepThird={setStepThird} />;
      case 3:
        return <Step3 stepFourth={stepFourth} setStepFourth={setStepFourth} />;
      case 4:
        return <Step4 stepFifth={stepFifth} setStepFifth={setStepFifth} />;
      default:
        return "unknown step";
    }
  };

  const getUser = async (e) => {
    axios
      .get("https://back.rentti.cl/v1/auth/me", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        console.log(res.data);
        setIdUsuario(res.data.id);
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const handleSubmit = async (e) => {
    getUser();
    const dataUsuario = new FormData();
    dataUsuario.append('img_referencial', img_referencial);
    dataUsuario.append('id_usuario', id_usuario);
    dataUsuario.append('id_corredor', id_corredor);
    dataUsuario.append('id_moneda', id_moneda);
    dataUsuario.append('id_operacion', id_operacion);
    dataUsuario.append('id_tipo_propiedad', id_tipo_propiedad);
    dataUsuario.append('id_tipo_nu', id_tipo_nu);
    dataUsuario.append('id_pais', id_pais);
    dataUsuario.append('id_zona', id_zona);
    dataUsuario.append('id_region', id_region);
    dataUsuario.append('id_ciudad', id_ciudad);
    dataUsuario.append('id_comuna', id_comuna);////
    dataUsuario.append('titulo', titulo);
    dataUsuario.append('descripcion', descripcion);
    dataUsuario.append('direccion', direccion);
    dataUsuario.append('longitud', longitud);
    dataUsuario.append('latitud', latitud);
    dataUsuario.append('construido_m2', construido_m2);
    dataUsuario.append('dormitorios', dormitorios);
    dataUsuario.append('banios', banios);
    dataUsuario.append('valor', valor);
    dataUsuario.append('fecha_publicacion', fecha_publicacion);
    dataUsuario.append('anio_construccion', anio_construccion);
    dataUsuario.append('sup_hab', sup_hab);
    dataUsuario.append('mt_utiles', mt_utiles);
    dataUsuario.append('mts_terrazas', mts_terrazas);
    dataUsuario.append('mts_totales', mts_totales);
    dataUsuario.append('mts_construidos', mts_construidos);
    dataUsuario.append('longitud_frente', longitud_frente);
    dataUsuario.append('longitud_fondo', longitud_fondo);
    dataUsuario.append('superficie_terreno', superficie_terreno);
    dataUsuario.append('privados', privados ? 1 : 0);
    dataUsuario.append('sala_reuniones', sala_reuniones ? 1 : 0);
    dataUsuario.append('secretaria', secretaria ? 1 : 0);
    dataUsuario.append('gastos_comunes', gastos_comunes ? 1 : 0);
    dataUsuario.append('contribuciones', contribuciones ? 1 : 0);
    dataUsuario.append('tipo_calefaccion', tipo_calefaccion);
    dataUsuario.append('tipo_piso', tipo_piso);
    dataUsuario.append('tipo_techumbre', tipo_techumbre);
    dataUsuario.append('n_bodega', n_bodega);
    dataUsuario.append('n_estacionamiento', n_estacionamiento);
    dataUsuario.append('estacionamineto_visitas', estacionamineto_visitas ? 1 : 0);
    dataUsuario.append('pisos_edificio', pisos_edificio);
    dataUsuario.append('orientacion', orientacion ? 1 : 0);
    dataUsuario.append('vista_depejada', vista_depejada ? 1 : 0);
    dataUsuario.append('colocacion', colocacion ? 1 : 0);
    dataUsuario.append('administracion', administracion ? 1 : 0);
    dataUsuario.append('letras', letras ? 1 : 0);
    dataUsuario.append('garantia', garantia ? 1 : 0);
    dataUsuario.append('custodia', custodia ? 1 : 0);

    if (idEdit) {
      if (id_usuario > 0) {
        try {
          const response = await axios({
            method: "post",
            url: `https://back.rentti.cl/v1/property/update/${idEdit}`,
            headers: {
              'Authorization': `Bearer ${token}`
            },
            data: dataUsuario
          });
          console.log(response);
          handleNext();
          // make axios post request
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      if (id_usuario > 0) {
        try {
          const response = await axios({
            method: "post",
            url: "https://back.rentti.cl/v1/property",
            headers: {
              'Authorization': `Bearer ${token}`
            },
            data: dataUsuario
          });
          console.log(response);
          handleNext();
          // make axios post request
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  const isStepOptional = (step) => {
    return step === 1 || step === 2;
  };

  const isStepSkipped = (step) => {
    return skippedSteps.includes(step);
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
    setSkippedSteps(
      skippedSteps.filter((skipItem) => skipItem !== activeStep)
    );
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSkip = () => {
    if (!isStepSkipped(activeStep)) {
      setSkippedSteps([...skippedSteps, activeStep]);
    }
    setActiveStep(activeStep + 1);
  };

  return (
    <div style={{ marginLeft: "20px", marginRight: "20px" }}>
      <Stepper alternativeLabel activeStep={activeStep}>
        {steps.map((step, index) => {
          const labelProps = {};
          const stepProps = {};
          if (isStepSkipped(index)) {
            stepProps.completed = false;
          }
          return (
            <Step {...stepProps} key={index}>
              <StepLabel {...labelProps}>{step}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {activeStep === steps.length ? (
        <Typography
          variant="h4"
          style={{ padding: "20px", background: "#3f51b5", color: "#fff" }}
          align="center"
        >
          Propiedad registrada
        </Typography>
      ) : (
        <div>
          <form>{getStepContent(activeStep)}</form>
          <Button
            className={classes.button}
            disabled={activeStep === 0}
            onClick={handleBack}
            variant="outlined"
            startIcon={<ArrowBackIosIcon />}
          >
            REGRESAR
          </Button>
          {idEdit ?
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
              endIcon={<ArrowForwardIosIcon />}
            >
              {activeStep === steps.length - 1 ? "GUARDAR DATOS" : "SIGUIENTE"}
            </Button>
            :
            <Button
              className={classes.button}
              variant="contained"
              color="primary"
              onClick={
                activeStep === steps.length - 1 ? handleSubmit : handleNext
              }
              endIcon={<ArrowForwardIosIcon />}
            >
              {activeStep === steps.length - 1 ? "AÑADIR INMUEBLE" : "SIGUIENTE"}
            </Button>
          }

        </div>
      )}
    </div>
  );
};


export default Publish;
